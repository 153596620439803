import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TextField, 
  Button, 
  Box,
  Typography,
  CircularProgress,
  Link,
  Pagination,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import BuyerDetails from './BuyerDetails';
import api from '../utils/api';

const MediaReimbursementList = () => {
  const [buyers, setBuyers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedBuyerId, setSelectedBuyerId] = useState(null);
  const [orderBy, setOrderBy] = useState('');
  const [orderDirection, setOrderDirection] = useState('asc');
  const [filterValue, setFilterValue] = useState('');
  const [downloadingDomestic, setDownloadingDomestic] = useState(false);
  const [downloadingInternational, setDownloadingInternational] = useState(false);

  const fetchBuyers = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('buyers/media-compact/', {
        params: {
          search: search,
          limit: 10,
          offset: (page - 1) * 10,
          ordering: orderBy ? `${orderDirection === 'desc' ? '-' : ''}${orderBy}` : null,
          refund_status: filterValue || null,
        }
      });
      setBuyers(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
    } catch (err) {
      setError('Failed to fetch buyers. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [search]);

  useEffect(() => {
    fetchBuyers();
  }, [search, page, orderBy, orderDirection, filterValue]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getFullImageUrl = (imageUrl) => {
    if (!imageUrl) return '';
    if (imageUrl.startsWith('http')) return imageUrl;
    return `https://api.tessst.com${imageUrl}`;
  };

  const handleBuyerClick = (buyerId) => {
    setSelectedBuyerId(buyerId);
  };

  const handleCloseModal = () => {
    setSelectedBuyerId(null);
  };

  const handleSort = (field) => {
    if (orderBy === field) {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(field);
      setOrderDirection('asc');
    }
  };

  const handleDownload = async () => {
    setDownloadingInternational(true);
    try {
      const endpoint = 'buyers/export-media-reimbursement/';
      
      const response = await api.get(endpoint, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `media-reimbursement.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      setError(`Failed to download media reimbursement data`);
    } finally {
      setDownloadingInternational(false);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Media List
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          sx={{ flexGrow: 1 }}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Refund Status</InputLabel>
          <Select
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            label="Refund Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="submitted">Submitted</MenuItem>
            <MenuItem value="processing">Processing</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="resubmit">Resubmit</MenuItem>
          </Select>
        </FormControl>
        <Button 
          variant="contained" 
          onClick={handleDownload}
          disabled={downloadingInternational}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {downloadingInternational ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={20} color="inherit" />
              Downloading...
            </Box>
          ) : (
            'Download'
          )}
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell 
                onClick={() => handleSort('registration')}
                sx={{ cursor: 'pointer' }}
              >
                Media ID {orderBy === 'registration' && (orderDirection === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell 
                onClick={() => handleSort('name')}
                sx={{ cursor: 'pointer' }}
              >
                Name {orderBy === 'name' && (orderDirection === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell 
                onClick={() => handleSort('organization_name')}
                sx={{ cursor: 'pointer' }}
              >
                Organization {orderBy === 'organization_name' && (orderDirection === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell 
                onClick={() => handleSort('refund_status')}
                sx={{ cursor: 'pointer' }}
              >
                Refund Status {orderBy === 'refund_status' && (orderDirection === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell>Photo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buyers.map((buyer) => (
              <TableRow key={buyer.registration}>
                <TableCell>{buyer.registration}</TableCell>
                <TableCell>
                  <Link 
                    component="button"
                    onClick={() => handleBuyerClick(buyer.registration)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {buyer.name}
                  </Link>
                </TableCell>
                <TableCell>{buyer.organization_name}</TableCell>
                <TableCell>{buyer.email}</TableCell>
                <TableCell>{buyer.refund_status}</TableCell>
                <TableCell>
                  {buyer.photo && (
                    <img 
                      src={getFullImageUrl(buyer.photo)} 
                      alt={buyer.name}
                      style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {!loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      <Dialog
        open={Boolean(selectedBuyerId)}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Media Reimbursement Details</DialogTitle>
        <DialogContent>
          {selectedBuyerId && (
            <BuyerDetails 
              buyerId={selectedBuyerId} 
              onClose={handleCloseModal}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MediaReimbursementList;