import React, { useState } from 'react';
import { 
  AppBar, 
  Box, 
  CssBaseline, 
  Drawer, 
  IconButton, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Toolbar, 
  Typography 
} from '@mui/material';
import { 
  Menu as MenuIcon, 
  Dashboard as DashboardIcon,
  ConfirmationNumber as ConfirmationNumberIcon ,
  People as PeopleIcon, 
  LocationOn as LocationOnIcon,
  ShoppingCart as ShoppingCartIcon, 
  Settings as SettingsIcon,
  QrCodeScanner as QrCodeScannerIcon,
  ReceiptLong as ReceiptLongIcon
} from '@mui/icons-material';
import EntryPass from './EntryPass';
import AccessPoints from './AccessPoints';
import SellerKitScanner from './SellerKitScanner';
import MartEntryScanner from './MartEntryScanner';
import MartExitScanner from './MartExitScanner';
import BuyersList from './BuyersList';
import BuyerKitScanner from './BuyerKitScanner';
import BuyersReimbursementList from './BuyersReimbursementList';
import MediaReimbursementList from './MediaReimbursementList';
import logo from '../images/pinlogo.jpeg'
const drawerWidth = 240;

const AdminDashboard = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Dashboard');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon /> },
    { text: 'Entry Passes', icon: <ConfirmationNumberIcon /> },
    { text: 'Access Points', icon: <LocationOnIcon /> },
    // { text: 'Seller Kit Scanner', icon: <QrCodeScannerIcon /> },
    { text: 'Conference Entry', icon: <QrCodeScannerIcon /> },
    { text: 'Conference Exit', icon: <QrCodeScannerIcon /> },
    { text: 'Workshop Entry', icon: <QrCodeScannerIcon /> },

    // { text: 'Buyers', icon: <PeopleIcon /> },
    { text: 'Reimbursement', icon: <ReceiptLongIcon /> },
    { text: 'Media Reimbursement', icon: <ReceiptLongIcon /> },
  ];

  const drawer = (
    <div>
      <Toolbar />
      <List>
         <img src="https://sprdh.com/wp-content/uploads/2023/08/sprdh-logo-1.png"  style={{ width: '100%',padding:20,marginTop:-40 }}/>
        {menuItems.map((item) => (
          <ListItem 
            button 
            key={item.text}
            selected={selectedItem === item.text}
            onClick={() => setSelectedItem(item.text)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {selectedItem === 'Entry Passes' && <EntryPass />}
        {selectedItem === 'Access Points' && <AccessPoints />}
        {selectedItem === 'Seller Kit Scanner' && <SellerKitScanner />}
        {selectedItem === 'Conference Entry' && <MartEntryScanner />} 
        {selectedItem === 'Conference Exit' && <MartExitScanner />}
        {selectedItem === 'Workshop Entry' && < BuyerKitScanner/>}
        {selectedItem === 'Buyers' && <BuyersList />}
        {selectedItem === 'Reimbursement' && <BuyersReimbursementList />}
        {selectedItem === 'Media Reimbursement' && <MediaReimbursementList />}
      </Box>
    </Box>
  );
};

export default AdminDashboard;